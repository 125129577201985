import getAssetPath from '../../utils/getAssetPath';

const onMessage = (data, props) => {
  const {
    inboxFolder,
    conversationStatusFilter,
    conversations,
    currentConversation,
    myDepartmentsIDs,
    currentUser,
    projectId,
  } = props;

  const {
    message,
    message: { user_id, sender_id },
    conversation_meta,
    conversation_meta: { conversation_status, department_id_assigned, assignee, conversation_id },
  } = data;

  var audio = new Audio(getAssetPath('notification_sound.wav'));

  const isCurrentFilter = conversation_status === conversationStatusFilter;
  const isAssignedToMe = assignee === currentUser.user_id;
  const isAssignedToThisDepartment = myDepartmentsIDs.includes(department_id_assigned);
  const isUnassigned = assignee === -1;
  const folderYou = inboxFolder.submenu === 'External' && inboxFolder.file.value === 'you';
  const folderUnassigned =
    inboxFolder.submenu === 'External' && inboxFolder.file.value === 'unassigned';
  const folderAll = inboxFolder.submenu === 'External' && inboxFolder.file.value === 'external_all';
  const currentlySelected = currentConversation.conversation_id === message.conversation_id;
  const isSentByMe = sender_id === currentUser.user_id;
  const conversationIsInList = conversations.some(
    (item) => item.conversation_id === conversation_id
  );
  const isInternalConversation =
    inboxFolder.submenu === 'Internal' && inboxFolder.file.value === 'internal_all';

  if (!isCurrentFilter) {
    return;
  }

  // CURRENTLY SELECTED CONVERSATION
  if (currentlySelected) {
    props.setToConversationHistory({ conversation_id, message });
    props.setConversationReadStatus({ conversation_id, project_id: projectId, user_id });
    // if the status becomes unread (unbolded), it means we need to update the counter
    props.removeFromExternalAllUnread({ conversation_id });
    if (isAssignedToMe) {
      props.removeFromYouUnread({ conversation_id });
    }
    if (audio && document.hidden && !isSentByMe) {
      audio.play().catch((err) => {
        console.error(err);
      });
    }
  }
  if (!currentlySelected) {
    if (isCurrentFilter) {
      if (isAssignedToMe && !isInternalConversation) {
        props.addToYouUnread({ conversation_id });
      }
      if (isAssignedToThisDepartment && isUnassigned) {
        props.addToUnassignedUnread({ conversation_id });
      }
      if (isAssignedToThisDepartment && !isInternalConversation) {
        props.addToExternalAllUnread({ conversation_id });
      }

      if (isInternalConversation) {
        props.addToInternalAllUnread({ conversation_id });
      }
    }
  }

  // UPDATE LIST
  if (conversationIsInList) {
    props.updateConversationInList({ conversation_id, conversation_meta });
  } else {
    if (
      (isAssignedToMe && folderYou) ||
      (isAssignedToThisDepartment && folderUnassigned && isUnassigned) ||
      (isAssignedToThisDepartment && folderAll)
    ) {
      props.setNewConversation({ conversation_id, conversation_meta });
    }
  }
};

export default onMessage;
