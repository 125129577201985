import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import languageMap from 'Language/Language';
import Axios from 'axios';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import MessageBlocks from '../../../../../Flow/components/Message/MessageBlocks';
import VerticalOrderedList from '../../../../../Flow/components/Message/VerticalOrderedList';
import updateMessage from '../../../../../../services/updateMessage';
import { AddButton } from '../../../../../../components/common/Button';
import InputText from '../../../../../../components/common/input/InputText';
import getProactiveMessages from './getProactiveMessages';

const cleanMessages = (messages) => {
  const cleanList = [];
  messages.forEach((msg) => {
    cleanList.push({
      message_id: msg.message_id,
      url_regex: msg.url_regex,
    });
  });
  return cleanList;
};
const ProactiveMessages = (props) => {
  const {
    proactive_messages,
    onChangeMessages,
    selectedLanguage,
    projectId,
    languageOptions,
    onChangeLanguage,
    changeIsLoadingRequest,
    isLoadingRequest,
  } = props;
  let [messages, setMessages] = useState([]);

  useEffect(() => {
    const getMessages = async () => {
      const messagesFetched = await getProactiveMessages(proactive_messages, projectId);
      setMessages(messagesFetched);
    };
    getMessages();
  }, []);

  const onOptionsByLanguageChange = (key, message_id) => async (value) => {
    let newMessages = [...messages];
    const message = newMessages.find((msg) => msg.message_id === message_id);
    message.message_properties[key] = {
      ...message.message_properties[key],
      [selectedLanguage]: value,
    };
    if (isLoadingRequest) {
      return;
    }
    changeIsLoadingRequest(true);
    const response = await updateMessage(message.message_properties, message_id, projectId, true);
    changeIsLoadingRequest(false);
    message.message_properties = response?.data?.message_details?.message_properties;
    setMessages(newMessages);
  };

  const onChangeAutomaticTranslation = (message_id) => async (value) => {
    let newMessages = [...messages];
    const message = newMessages.find((msg) => msg.message_id === message_id);
    message.message_properties = {
      ...message.message_properties,
      automaticTranslation: value,
    };
    if (isLoadingRequest) {
      return;
    }
    changeIsLoadingRequest(true);
    const response = await updateMessage(message.message_properties, message_id, projectId, true);
    changeIsLoadingRequest(false);
    message.message_properties = response?.data?.message_details?.message_properties;
    setMessages(newMessages);
  };

  const addMessage = async () => {
    if (isLoadingRequest) {
      return;
    }
    changeIsLoadingRequest(true);
    let response;
    try {
      response = await Axios.post('/internal_api/message', {
        appId: 0,
        project_id: projectId,
      });
    } catch (err) {
      console.log(err);
      changeIsLoadingRequest(false);
      return;
    }

    let newMessages = [
      ...messages,
      {
        message_id: response.data.message_id,
        message_properties: JSON.parse(response.data.message_properties),
        url_regex: '.*',
      },
    ];
    setMessages(newMessages);
    changeIsLoadingRequest(false);
    onChangeMessages('proactive_messages', cleanMessages(newMessages));
  };

  const deleteMessage = (message_id) => async () => {
    if (isLoadingRequest) {
      return;
    }
    changeIsLoadingRequest(true);
    let newMessages = messages.filter((msg) => msg.message_id !== message_id);
    await Axios.delete('/internal_api/message', { data: { id: message_id } });

    setMessages(newMessages);
    changeIsLoadingRequest(false);

    onChangeMessages('proactive_messages', cleanMessages(newMessages));
  };

  const onChangeUrlRegex = (message_id) => (name, value) => {
    let newMessages = [...messages];
    const message = newMessages.find((msg) => msg.message_id === message_id);
    message.url_regex = value;
    setMessages(newMessages);
  };

  const onSaveUrlRegex = () => {
    onChangeMessages('proactive_messages', cleanMessages(messages));
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 600,
          color: '#56595f',
        }}
      >
        {languageMap.proactiveMessage}
        {languageOptions.length > 1 && (
          <Dropdown
            className="languageDropdown"
            selection
            value={selectedLanguage}
            options={languageOptions}
            onChange={onChangeLanguage}
          />
        )}
        <AddButton
          text={languageMap.proactiveMessageAdd}
          icon="add"
          style={{ marginLeft: 10, minWidth: 40, padding: 8 }}
          onClick={addMessage}
        />
      </div>
      {messages.map((msg) => {
        const { message_properties, url_regex, message_id } = msg;
        const { responseOptionsByLanguage, replySuggestionsByLanguage, automaticTranslation } =
          message_properties;
        const currentResponseOptions = responseOptionsByLanguage[selectedLanguage];
        const currentReplySuggestions = replySuggestionsByLanguage[selectedLanguage];
        return (
          <div key={message_id}>
            <div
              className="WoocommerceConfiguration"
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <InputText
                style={{ marginLeft: 8 }}
                name="Url regex"
                title="Url regex"
                value={url_regex}
                onValueChanged={onChangeUrlRegex(message_id)}
                onBlur={onSaveUrlRegex}
              />
              <IconButton onClick={deleteMessage(message_id)} size="large">
                <Delete style={{ width: '30px', height: '30px' }} />
              </IconButton>
            </div>

            <MessageBlocks
              hideTitle={true}
              messageId={message_id}
              projectId={projectId}
              options={currentResponseOptions}
              voiceInteraction={false}
              automaticTranslation={automaticTranslation}
              onChangeAutomaticTranslation={onChangeAutomaticTranslation(message_id)}
              onChange={onOptionsByLanguageChange('responseOptionsByLanguage', message_id)}
              selectedLanguage={selectedLanguage}
              loadingChange={changeIsLoadingRequest}
            />
            <div>
              <VerticalOrderedList
                title={languageMap.suggestionsLabel}
                elements={currentReplySuggestions || []}
                onChange={onOptionsByLanguageChange('replySuggestionsByLanguage', message_id)}
                selectedLanguage={selectedLanguage}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default ProactiveMessages;
