const onAssign = (data, props) => {
  const { inboxFolder, conversations, currentConversation, myDepartmentsIDs, currentUser } = props;

  const {
    conversation_meta,
    conversation_meta: { conversation_id },
    previous_department_id_assigned,
    department_id_assigned,
    previous_assignee,
    assignee,
  } = data;

  const didDepartmentChange = department_id_assigned !== previous_department_id_assigned;
  const didAgentChange = assignee !== previous_assignee;
  const previousDepartmentBelongsToMe = myDepartmentsIDs.includes(previous_department_id_assigned);
  const currentDepartmentBelongsToMe = myDepartmentsIDs.includes(department_id_assigned);
  const isUnassigned = assignee === -1;
  const isFolderYou = inboxFolder.submenu === 'External' && inboxFolder.file.value === 'you';
  const isFolderUnassigned =
    inboxFolder.submenu === 'External' && inboxFolder.file.value === 'unassigned';
  const isFolderAll =
    inboxFolder.submenu === 'External' && inboxFolder.file.value === 'external_all';

  const isAssignedToMe = assignee === currentUser.user_id;
  const wasAssignedToMe = previous_assignee === currentUser.user_id;
  const conversationIsInList = conversations.some(
    (item) => item.conversation_id === conversation_id
  );
  const currentlySelected = currentConversation.conversation_id === conversation_id;

  // COMPUTE COUNTS
  if (didDepartmentChange) {
    if (previousDepartmentBelongsToMe) {
      if (!currentDepartmentBelongsToMe) {
        if (isUnassigned) {
          props.decrementUnassigned();
          props.removeFromUnassignedUnread({ conversation_id });
        }
      }
      props.decrementAll();
      props.removeFromExternalAllUnread({ conversation_id });
    }
    if (currentDepartmentBelongsToMe) {
      if (!previousDepartmentBelongsToMe) {
        if (isUnassigned) {
          props.incrementUnassigned();
          props.addToUnassignedUnread({ conversation_id });
        }
      }
      props.incrementAll();
      if (!currentlySelected) {
        props.addToExternalAllUnread({ conversation_id });
      }
    }
  }
  if (wasAssignedToMe) {
    props.decrementYou();
    props.removeFromYouUnread({ conversation_id });
  }
  if (isAssignedToMe) {
    props.incrementYou();
    props.addToYouUnread({ conversation_id });
  }

  if (didAgentChange && currentDepartmentBelongsToMe) {
    if (isUnassigned) {
      props.incrementUnassigned();
      props.addToUnassignedUnread({ conversation_id });
      props.addToExternalAllUnread({ conversation_id });
    }
    if (!isUnassigned) {
      props.decrementUnassigned();
      props.removeFromUnassignedUnread({ conversation_id });
      props.addToExternalAllUnread({ conversation_id });
    }
  }

  // UPDATE LIST AGENTS
  if (isFolderYou) {
    if (isAssignedToMe) {
      props.setNewConversation({ conversation_id, conversation_meta });
    }
    if (wasAssignedToMe && conversationIsInList) {
      props.removeConversation({ conversation_id });
    }
  }

  if (didAgentChange && isFolderUnassigned) {
    if (isUnassigned) {
      props.setNewConversation({ conversation_id, conversation_meta });
    }
    if (!isUnassigned) {
      props.removeConversation({ conversation_id });
      // if (currentUser.user_id === assignee) {
      // }
    }
  }

  // UPDATE LIST DEPARTMENTS
  if (didDepartmentChange) {
    if (isFolderUnassigned) {
      if (currentDepartmentBelongsToMe && isUnassigned) {
        props.setNewConversation({ conversation_id, conversation_meta });
      }
      if (previousDepartmentBelongsToMe) {
        props.removeConversation({ conversation_id });
      }
    }
    if (isFolderAll) {
      if (currentDepartmentBelongsToMe) {
        props.setNewConversation({ conversation_id, conversation_meta });
      }
      if (previousDepartmentBelongsToMe) {
        props.removeConversation({ conversation_id });
      }
    }
  }

  // CURRENTLY SELECTED CONVERSATION

  if (currentDepartmentBelongsToMe) {
    props.updateConversationInList({ conversation_id, conversation_meta });
    if (currentlySelected) {
      props.setCurrentConversation(conversation_meta);
    }
  }
};

export default onAssign;
